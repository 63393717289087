import React, { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import ControlledTextField from '../RiverusUI/Components/ControlledTextField';
import CustomModal from '../RiverusUI/Components/CustomModal';
import { createRole, editRole } from '../Services/keycloak';
import { QueryKeyGenerator } from '../Utils/QueryKeyGenerator';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  editDetails?: any;
  rolesTableKey?: any;
}

const CreateRolesModal: React.FC<Props> = ({
  open,
  onClose,
  editDetails,
  rolesTableKey,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const methods = useForm();
  const { handleSubmit, control, setValue } = methods;

  useEffect(() => {
    if (editDetails) {
      setValue('role_name', editDetails?.name);
      setValue('description', editDetails?.description);
    }
  }, [editDetails, setValue]);

  const { mutate: createRoleMutation, isPending: loadingCreateRole } =
    useMutation({
      mutationFn: createRole,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            QueryKeyGenerator.getRequestApproval(),
            'keycloak_roles',
            rolesTableKey,
          ],
        });
        enqueueSnackbar('Role created successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        onClose();
      },
      onError: (error: any) => {
        const responseData = error?.response?.data?.errorMessage;
        enqueueSnackbar(`${responseData || 'Failed to create Role!'}`, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: editRoleMutation, isPending: loadingEditRole } = useMutation({
    mutationFn: editRole,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeyGenerator.getRequestApproval(),
          'keycloak_roles',
          rolesTableKey,
        ],
      });
      enqueueSnackbar('Role edited successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      onClose();
    },
  });

  const onSubmit = (data: any) => {
    if (!editDetails) {
      const payload = {
        name: data?.role_name,
        description: data?.description,
      };
      createRoleMutation(payload);
    } else {
      const payload = {
        id: editDetails?.id,
        body: {
          id: editDetails?.id,
          name: data?.role_name,
          description: data?.description,
        },
      };
      editRoleMutation(payload);
    }
  };

  return (
    <CustomModal
      title={`${editDetails ? 'Edit' : 'Create'} a Role`}
      maxWidth="lg"
      open={open}
      handleClose={onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data: any) => onSubmit(data))}>
          <Stack spacing={3} sx={{ padding: '30px 15px 15px' }}>
            <ControlledTextField
              name="role_name"
              control={control}
              required
              label="Role Name"
              fullWidth
            />
            <ControlledTextField
              name="description"
              control={control}
              label="Description"
              fullWidth
            />
            <Stack spacing={2}>
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
              >
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={loadingCreateRole || loadingEditRole}
                >
                  {editDetails ? 'Save' : 'Create'}
                </LoadingButton>
                <Button
                  variant="outlined"
                  startIcon={<CloseIcon />}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default CreateRolesModal;

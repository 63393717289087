/* eslint-disable react/no-unescaped-entities */
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { KeyboardReturn } from '@mui/icons-material';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { WebViewerInstance } from '@pdftron/webviewer';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';

import AddSignatories from './AddSignatories';
import { useUserData } from '../../../App/Component/UserDataProvider';
import CustomChip from '../../../Approvals/Component/CreateApprovalForm/CustomChip';
import { getSessionStorage } from '../../../Authentication/Actions/authentication';
import { editExternalReminder } from '../../../ExternalUserFlow/Services/Draft';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import NameAvatar from '../../../RiverusUI/DataGrid/NameAvatar';
import { editReminder } from '../../../Services/DocumentLibrary';
import {
  draftSignatures,
  getDraft,
  getSignatureDetails,
  onSignedStatus,
  reInitiateSignatureMail,
  sendAgreement,
  updateDraftSignatures,
  updateSignatureDetails,
  uploadDocument,
} from '../../../Services/signatureTab';
import { fetchStamps, updateStampPaper } from '../../../Services/Stamp';
import { draftStatus } from '../../State/DraftState';
import { getTimezoneAbbreviation } from '../Helper';

interface Props {
  signatoriesList: any[];
  isLoggedInUser: any;
  addedSignatureFields: boolean;
  draftData: any;
  handleConfirmation: any;
  isExternal?: boolean;
  sendDraftConfirmation?: boolean;
  editModeDisabled?: boolean;
  instance: WebViewerInstance | null;
  setAddedSignatureFields: Dispatch<SetStateAction<boolean>>;
  setDocumentSigned: Dispatch<SetStateAction<boolean>>;
  declineSignature: VoidFunction;
  sendForAdobeSigning?: boolean;
  internalSignatoryId?: string;
  signatoriesData?: any[];
  externalUsersData?: any[];
  loadingTempSignatory?: boolean;
  handleUserType: VoidFunction;
  updateSignatory: any;
  isLoading: boolean;
  accessUrl?: any;
  setSignedDate: Dispatch<SetStateAction<string | undefined>>;
  authorData?: any;
}

const SignatoriesList: React.FC<Props> = ({
  signatoriesList,
  isLoggedInUser,
  addedSignatureFields,
  draftData,
  handleConfirmation,
  isExternal,
  editModeDisabled,
  declineSignature,
  instance,
  setAddedSignatureFields,
  setDocumentSigned,
  sendForAdobeSigning,
  internalSignatoryId,
  signatoriesData,
  externalUsersData,
  handleUserType,
  loadingTempSignatory,
  updateSignatory,
  isLoading,
  accessUrl,
  setSignedDate,
  authorData,
}) => {
  const queryClient = useQueryClient();
  const [decline, setDecline] = useState<boolean>(false);
  const [email, setEmail] = useState<string[]>([]);
  const [signatureName, setSignatureName] = useState<string[]>([]);
  const [signAccessToken, setSignAccessToken] = useState<string>();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [declinedSignatories, setDeclinedSignatories] = useState<any>([]);
  const [isReplaceSignatory, setIsReplaceSignatory] = useState<number | null>(
    null
  );
  const [replaceAlert, setShowReplaceAlert] = useState(false);
  const [isAllSignatorySigned, setIsAllSignatorySigned] =
    useState<boolean>(false);
  const [loadingConfirmations, setLoadingConfirmations] =
    useState<boolean>(false);

  const statusUpdated = useRef(false);

  const draftFileName = React.useMemo(
    () => draftData?.contractName,
    [draftData]
  );

  const { enqueueSnackbar } = useSnackbar();

  const openDeclineDialog = () => {
    setDecline(true);
  };

  const closeDeclineDialog = () => {
    setDecline(false);
  };

  const { data: draftStamps } = useQuery({
    queryKey: ['draft-stamps'],
    queryFn: () => fetchStamps(`?draft=${draftData?.id}`),
    enabled: !!draftData?.id && !isExternal,
  });

  const { mutate: updateStampPaperMutation } = useMutation({
    mutationKey: ['update_stamp_paper'],
    mutationFn: updateStampPaper,
  });

  const handleDecline = async () => {
    declineSignature();
    setDecline(false);

    const ids = draftStamps?.results?.map((stamp: any) => stamp?.id) || [];
    const payloadStampDecline = {
      status: 'UNUSED',
      draft: null,
      used_on: null,
    };

    for (const id of ids) {
      const payload = { id, body: payloadStampDecline };
      await updateStampPaperMutation(payload);
    }

    enqueueSnackbar('Your Preference has been noted', {
      variant: 'info',
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
  };

  const showSignButton = useCallback(
    (signatory: any) => {
      if (
        isLoggedInUser(signatory?.id, signatory?.email, signatory?.user_type) &&
        !signatory?.signed_date &&
        draftData?.signature_method === 'Stylus (Riverus)' &&
        !editModeDisabled &&
        draftData.status !== draftStatus.SIGNATURE_ABORTED
      ) {
        return true;
      }
      return false;
    },
    [draftData, isLoggedInUser, editModeDisabled]
  );

  useEffect(() => {
    if (getSessionStorage('signAccessToken')) {
      setSignAccessToken(getSessionStorage('signAccessToken') || undefined);
    } else {
      window.addEventListener('storage', () => {
        const initialToken = getSessionStorage('signAccessToken');
        if (initialToken) {
          setSignAccessToken(initialToken);
        }
      });
    }
  }, []);

  const { mutate: onSignedMutation } = useMutation({
    mutationKey: ['on_signed', draftData?.id],
    mutationFn: async (status: string) =>
      await onSignedStatus(draftData?.id, status),
  });

  const { mutate: uploadMutation, data: uploadDocumentData } = useMutation({
    mutationKey: ['upload_document'],
    mutationFn: async (formData: any) => await uploadDocument(formData),
  });

  const { mutate: signDetailsMutation } = useMutation({
    mutationKey: ['sign_details'],
    mutationFn: async (payload: any) => await updateSignatureDetails(payload),
  });

  const { mutate: agreementIdMutation, data: agreementIdData } = useMutation({
    mutationKey: ['agreement_id'],
    mutationFn: async (payload: any) => await sendAgreement(payload),
  });

  const { mutate: reSendSignature } = useMutation({
    mutationKey: ['resend-signature'],
    mutationFn: reInitiateSignatureMail,
    onSuccess: () => {
      enqueueSnackbar('Resend signature request sent successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to resend signature request!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { data: signatureAgreementData } = useQuery({
    queryKey: ['signature_agreement', draftData?.id],
    queryFn: () => draftSignatures(draftData?.id),
    select: (response: any) => {
      if (response?.status === 'SIGNED' && !statusUpdated.current) {
        statusUpdated.current = true;
        onSignedMutation('Contract Executed Successfully');
      }
      return response;
    },
    enabled: !isExternal && !!draftData?.id,
  });

  const { data: signatureDetailsData } = useQuery({
    queryKey: ['signature_details', draftData?.id, signatureAgreementData],
    queryFn: () =>
      getSignatureDetails(draftData?.id, signatureAgreementData?.agreement),
    enabled: !!signatureAgreementData?.agreement && !isExternal,
  });

  const { data: documentData } = useQuery({
    queryKey: ['send_document'],
    queryFn: async () => await getDraft(accessUrl),
    select: (response: any) => {
      return response;
    },
    enabled: sendForAdobeSigning && !isExternal && !!accessUrl,
  });

  useEffect(() => {
    if (documentData && sendForAdobeSigning) {
      const myFile = new File([documentData], draftFileName + '.pdf');
      const formData = new FormData();
      formData.append('File', myFile);
      uploadMutation(formData);
    }
  }, [documentData, draftFileName, sendForAdobeSigning, uploadMutation]);

  useEffect(() => {
    if (!isExternal && uploadDocumentData) {
      if (email.length === signatoriesList.length) {
        const participantSetsInfo: any[] = email.map((value, index) => {
          return {
            memberInfos: [{ email: email[index] }],
            order: index + 1,
            role: 'SIGNER',
          };
        });
        const agreementInfo = {
          fileInfos: [
            {
              transientDocumentId:
                uploadDocumentData?.data?.transientDocumentId,
            },
          ],
          name: draftFileName + ' - Agreement',
          participantSetsInfo: participantSetsInfo,
          signatureType: 'ESIGN',
          state: 'IN_PROCESS',
        };
        agreementIdMutation(agreementInfo);
      }
    }
  }, [
    agreementIdMutation,
    draftFileName,
    email,
    isExternal,
    signatoriesList,
    uploadDocumentData,
  ]);

  const { data: addSignatureDetailsData } = useQuery({
    queryKey: ['add_signature_details'],
    queryFn: async () =>
      await updateDraftSignatures(agreementIdData?.data?.id, draftData?.id),
    select: (response: any) => {
      return response;
    },
    enabled: !!agreementIdData && !isExternal,
  });

  useEffect(() => {
    if (agreementIdData && draftData) {
      const signatureDetails: any[] = email.map((element, index) => {
        return { email: email[index], name: signatureName[index] };
      });
      const payload = {
        draft: draftData.id,
        agreement: agreementIdData.data?.id,
        signatures: signatureDetails,
      };
      signDetailsMutation(payload);
    }
  }, [agreementIdData, draftData, email, signDetailsMutation, signatureName]);

  const agreementStatus = React.useMemo(() => {
    if (addSignatureDetailsData?.status) {
      return addSignatureDetailsData?.status;
    } else {
      if (signatureAgreementData?.message) {
        return signatureAgreementData?.message;
      } else if (signatureAgreementData?.status) {
        return signatureAgreementData?.status;
      }
    }
  }, [addSignatureDetailsData, signatureAgreementData]);

  const displaySignatories = React.useMemo(() => {
    if (
      (agreementStatus &&
        agreementStatus !== 'SIGNED' &&
        !draftData?.status
          ?.toLowerCase()
          .includes('contract executed successfully')) ||
      (draftData?.signature_method && draftData?.signature_method !== 'Adobe')
    ) {
      return true;
    } else {
      return false;
    }
  }, [agreementStatus, draftData?.status, draftData?.signature_method]);

  useEffect(() => {
    if (
      sendForAdobeSigning &&
      draftData?.signature_method === 'Adobe' &&
      agreementStatus !== 'SIGNED' &&
      signAccessToken
    ) {
      const savedSignAccessToken = getSessionStorage('signAccessToken');
      setSignAccessToken(savedSignAccessToken || undefined);
    }
  }, [
    sendForAdobeSigning,
    draftData?.signature_method,
    agreementStatus,
    signAccessToken,
  ]);

  useEffect(() => {
    const emailList: any[] = [];
    const signatureNameList: any[] = [];
    signatoriesList.map((signatory: any, index: number) => {
      const signatoryEmail = signatory.email;
      emailList[index] = signatoryEmail;
      if (signatory.user_type === 'internal') {
        const signatureName =
          signatory?.first_name + ' ' + signatory?.last_name;
        signatureNameList[index] = signatureName;
      } else {
        const signatureName = signatory?.name;
        signatureNameList[index] = signatureName;
      }
    });
    setEmail(emailList);
    setSignatureName(signatureNameList);
  }, [signatoriesList]);

  const { user_id, user_data, external_email_id, external_signatory_id } =
    useUserData();

  const id = useMemo(
    () =>
      isExternal
        ? external_signatory_id || external_email_id
        : internalSignatoryId || user_data?.email,
    [
      isExternal,
      external_signatory_id,
      external_email_id,
      user_data?.email,
      internalSignatoryId,
    ]
  );

  const userIsOwner = useMemo(
    () => draftData?.owners?.find((owner: any) => owner?.id === user_id),
    [draftData, user_id]
  );

  useEffect(() => {
    if (!instance) return;
    const { documentViewer, Annotations } = instance.Core;
    const annotationManager = documentViewer.getAnnotationManager();

    annotationManager.addEventListener('annotationChanged', (annotations) => {
      const assignedField = annotations.filter(
        (annot: any) =>
          annot &&
          (annot.getCustomData('value') === id ||
            annot.Subject?.toLowerCase() !== 'signature')
      );
      const signedFields = annotationManager
        .getAnnotationsList()
        .filter(
          (annot: any) =>
            annot instanceof Annotations.SignatureWidgetAnnotation &&
            annot.getCustomData('user') === id
        )
        .filter((annot: any) => annot.isSignedByAppearance());
      if (signedFields?.length > 0 && assignedField?.length === 0) {
        setIsSubmitDisabled(false);
      } else {
        setIsSubmitDisabled(true);
      }
    });
    setIsSubmitDisabled(true);
  }, [instance, agreementStatus, id]);

  const { mutate: update_reminder } = useMutation({
    mutationKey: ['update_reminder'],
    mutationFn: isExternal ? editExternalReminder : editReminder,
    onSuccess: () => {
      enqueueSnackbar('Reminder updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to update Reminder!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  useEffect(() => {
    const updateStamps = async () => {
      if (draftData?.status === 'Contract Executed Successfully') {
        const ids = draftStamps?.results?.map((stamp: any) => stamp?.id) || [];
        const payloadStampSubmit = {
          status: 'USED',
          draft: draftData?.id,
        };

        for (const id of ids) {
          const payload = { id, body: payloadStampSubmit };
          await updateStampPaperMutation(payload);
        }
      }
    };

    updateStamps();

    const FilterNotSignSignatory = draftData?.signatories?.filter(
      (item: any) => item?.status !== 'Signature Signed'
    );

    if (!FilterNotSignSignatory?.length) {
      setIsAllSignatorySigned(true);
    } else {
      setIsAllSignatorySigned(false);
    }
  }, [draftData, draftStamps]);

  const goToSignField = useCallback(() => {
    if (!instance) return;
    const { documentViewer } = instance.Core;
    const annotationManager = documentViewer.getAnnotationManager();
    const annotationList = annotationManager.getAnnotationsList();

    const selectedAnnotation = annotationList.filter(
      (annot: any) =>
        annot &&
        annot.getCustomData('user') === id &&
        annot.Subject?.toLowerCase() === 'widget'
    );
    if (selectedAnnotation?.[0]) {
      annotationManager.jumpToAnnotation(selectedAnnotation[0]);
    }
  }, [instance, id]);

  const submitSignature = async () => {
    if (!instance) return;
    setLoadingConfirmations(true);
    const name = isExternal
      ? external_email_id
      : `${user_data?.first_name} ${user_data?.last_name}`;

    const { documentViewer, Annotations } = instance.Core;
    const annotationManager = documentViewer.getAnnotationManager();
    const annotationsList = annotationManager.getAnnotationsList();

    const annotsToDraw: any = [];

    const date = new Date();
    const signedDate = `${dayjs(date).format('DD/MM/YYYY, h:mm A')} ${getTimezoneAbbreviation(date)}`;
    setSignedDate(signedDate);
    await Promise.all(
      annotationsList.map(async (annot: any) => {
        if (
          annot &&
          annot.Subject?.toLowerCase() === 'free text' &&
          annot.getCustomData('user') === id
        ) {
          annot.setCustomData('user', '');
          annot.setContents(name);
          annot.TextAlign = 'left';
          annot.FontSize = '12px';
          annot.TextColor = new Annotations.Color(18, 13, 61);
          const dateAnnot = new Annotations.FreeTextAnnotation();
          dateAnnot.setContents(signedDate);
          dateAnnot.PageNumber = annot.getPageNumber();
          dateAnnot.X = annot.getX();
          dateAnnot.Y = annot.getY() + 13;
          dateAnnot.Rotation = annot.Rotation;
          if (annot.Rotation === 0 || annot.Rotation === 180) {
            dateAnnot.Width = annot.getWidth();
            dateAnnot.Height = annot.getHeight();
          } else {
            dateAnnot.Width = annot.getHeight();
            dateAnnot.Height = annot.getWidth();
          }
          dateAnnot.FontSize = '12px';
          dateAnnot.TextAlign = 'left';
          dateAnnot.TextColor = new Annotations.Color(18, 13, 61);
          dateAnnot.StrokeThickness = 0;

          annotationManager.addAnnotation(dateAnnot);
          annotsToDraw.push(dateAnnot);
        }
      })
    );

    const userAnnot = annotationsList.filter((annot: any) => {
      return (
        annot &&
        annot.getCustomData('user') !== '' &&
        annot.Subject?.toLowerCase() !== 'widget'
      );
    });

    if (userAnnot.length === 0) {
      setDocumentSigned(true);
    }
    // refresh viewer
    await annotationManager.drawAnnotationsFromList(annotsToDraw);

    const currentSignatory = signatoriesList.filter(
      (signatory: any) => signatory?.id === user_id
    );

    if (currentSignatory[0]?.reminder?.id) {
      const id = currentSignatory[0]?.reminder?.id;
      const payload = { id, body: { status: 'Completed' } };
      update_reminder(payload);
    }
    handleConfirmation();
  };

  const handleResendSignatureRequest = useCallback(() => {
    const payload = {
      draft: draftData?.id,
    };
    reSendSignature(payload);
  }, [draftData?.id]);

  const handleCloseConfirmation = () => {
    setLoadingConfirmations(false);
    setAddedSignatureFields(false);
  };

  useEffect(() => {
    const filterDeclinedSignatories = draftData?.signatories?.filter(
      (signatory: any) => signatory?.status === 'Signature Aborted'
    );
    setDeclinedSignatories(filterDeclinedSignatories);
  }, [draftData?.signatories, draftData?.status]);

  const handleClearSignature = () => {
    if (isExternal) {
      queryClient.invalidateQueries({
        queryKey: ['view_reference_document_link_external'],
      });
    } else {
      queryClient.invalidateQueries({
        queryKey: ['view_reference_document_link'],
      });
    }
  };

  const signButton = useCallback(
    (signatoryData: any) => {
      const priority = signatoryData?.priority;
      let disabled = false;
      let text = 'Signed';
      if (draftData?.signatory_sequence === 'all_in_sequence') {
        if (priority === 1) return { disabled: false, text };
        draftData?.signatories?.map((data: any) => {
          if (data?.priority < priority) {
            if (!data?.signed_date && !data?.declined_date) {
              disabled = true;
              text = `Your signature can be submitted only after ${data?.name ? `${data?.name}'s` : `${data?.first_name} ${data?.last_name}'s`} signature`;
            }
          }
          return {
            disabled,
            text,
          };
        });
      }
      return { disabled, text };
    },
    [draftData?.signatories]
  );

  const onSubmit = (data: any, signatory: any, index: number) => {
    const updatedSignatory = [...(draftData?.signatories || [])];
    const isAlreadySignatory = updatedSignatory.some(
      (user: any) =>
        user?.id === data.name || user?.id === data.select_external_user
    );
    if (isAlreadySignatory) {
      enqueueSnackbar('This signatory is already present!', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    } else {
      if (signatory.user_type === 'internal') {
        const internalUserData = signatoriesData?.find(
          (user: any) => user.id === data.name
        );
        updatedSignatory[index] = {
          ...signatory,
          ...internalUserData,
          assigned_date: new Date().toLocaleDateString('en-GB'),
          is_changed: true,
          old_email: signatory.email,
        };
      } else {
        const externalUserData = externalUsersData?.find(
          (user: any) => user?.id === data?.select_external_user
        );
        updatedSignatory[index] = {
          ...signatory,
          ...externalUserData,
          assigned_date: new Date().toLocaleDateString('en-GB'),
          is_changed: true,
          old_email: signatory.email,
        };
      }
    }

    const groups = draftData?.groups;
    updatedSignatory?.forEach((approver: any) => {
      const missingInDraftGroups = approver?.groups?.filter(
        (item: string) => !draftData?.groups?.includes(item)
      );
      if (missingInDraftGroups?.length) {
        groups.push(...missingInDraftGroups);
      }
    });
    const payload = {
      id: draftData?.id,
      body: {
        signatories: updatedSignatory,
        groups: groups,
      },
    };
    updateSignatory(payload);
  };

  const isUserActive = (signatory: any) => {
    const matchedAuth = authorData?.find(
      (auth: any) =>
        auth?.email === signatory?.email || auth?.id === signatory?.id
    );
    return matchedAuth ? matchedAuth?.is_active : null;
  };

  return (
    <>
      <Stack>
        {displaySignatories &&
          draftData?.signatories?.map((signatory: any, index: number) => {
            const isActive = isUserActive(signatory);
            return (
              <Box
                key={`${signatory?.id}-${index}`}
                sx={{ backgroundColor: '#88305f1f' }}
                borderRadius="10px"
                padding={2}
                marginY={2}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Tooltip
                    title={
                      !isActive &&
                      (signatory?.assignee_type !== 'external' ||
                        signatory?.user_type !== 'external')
                        ? `${signatory?.first_name} ${signatory?.last_name} is now inactive, please replace it with another signatory.`
                        : ''
                    }
                  >
                    <Stack
                      direction="row"
                      alignItems="start"
                      justifyContent="space-between"
                      spacing={1}
                    >
                      <Stack direction="row">
                        <NameAvatar
                          firstName={
                            signatory?.assignee_type === 'external' ||
                            signatory?.user_type === 'external'
                              ? signatory?.first_name?.split(' ')[0] ||
                                signatory?.name
                              : signatory?.first_name
                          }
                          lastName={
                            signatory?.assignee_type === 'external' ||
                            signatory?.user_type === 'external'
                              ? ''
                              : signatory?.last_name
                          }
                          avatarStyle={{
                            backgroundColor:
                              !isActive &&
                              (signatory?.assignee_type !== 'external' ||
                                signatory?.user_type !== 'external')
                                ? 'rgba(0, 0, 0, 0.15)'
                                : '#A0597F',
                          }}
                          disableTooltip={
                            !isActive &&
                            (signatory?.assignee_type !== 'external' ||
                              signatory?.user_type !== 'external')
                          }
                        />
                        {!signatory.signed_date && !signatory.declined_date && (
                          <Stack marginLeft={2}>
                            <Typography variant="body1">
                              {signatory?.first_name
                                ? signatory?.first_name === signatory?.last_name
                                  ? signatory.first_name
                                  : `${signatory?.first_name} ${signatory?.last_name}`
                                : signatory?.name}{' '}
                              signature pending
                            </Typography>
                            <Typography color="#2b2a2a" fontSize="12px">
                              {signatory?.email}
                            </Typography>
                          </Stack>
                        )}

                        {signatory.signed_date && !signatory.declined_date && (
                          <Typography variant="body1" marginLeft={2}>
                            {signatory?.first_name
                              ? signatory?.first_name === signatory?.last_name
                                ? signatory.first_name
                                : `${signatory?.first_name} ${signatory?.last_name}`
                              : signatory?.name}{' '}
                            has already signed
                          </Typography>
                        )}
                        {signatory?.status === 'Signature Aborted' && (
                          <Typography variant="body1" marginLeft={2}>
                            {signatory?.first_name
                              ? signatory?.first_name === signatory?.last_name
                                ? signatory.first_name
                                : `${signatory?.first_name} ${signatory?.last_name}`
                              : signatory?.name}{' '}
                            has declined the signature
                          </Typography>
                        )}
                      </Stack>
                      {!isActive &&
                        (signatory?.assignee_type !== 'external' ||
                          signatory?.user_type !== 'external') && (
                          <CustomChip
                            sx={{
                              background: '#f3eaef',
                              padding: '10px 4px',
                              borderRadius: '5px',
                              '& .MuiChip-label': {
                                paddingRight: '0',
                                paddingLeft: '4px',
                                fontSize: '12px',
                              },
                            }}
                            label="Inactive"
                          />
                        )}
                    </Stack>
                  </Tooltip>
                  {!signatory.signed_date &&
                    !signatory.declined_date &&
                    userIsOwner &&
                    !isExternal && (
                      <Button
                        onClick={() => {
                          if (signatory.signatory_id) {
                            setIsReplaceSignatory(signatory.id);
                            handleUserType();
                          } else {
                            setShowReplaceAlert(true);
                          }
                        }}
                        sx={{ padding: 0 }}
                      >
                        Replace Signatory
                      </Button>
                    )}
                  {signatory?.signed_date && (
                    <Typography variant="body2">
                      {dayjs(signatory?.signed_date)?.isValid()
                        ? `${dayjs(signatory?.signed_date).format(
                            'DD/MM/YYYY, h:mm A'
                          )} IST`
                        : signatory?.signed_date}
                    </Typography>
                  )}
                  {signatory?.declined_date && (
                    <Typography variant="body2">
                      {dayjs(signatory?.declined_date)?.isValid()
                        ? `${dayjs(signatory?.declined_date).format(
                            'DD/MM/YYYY, h:mm A'
                          )} IST`
                        : signatory?.declined_date}
                    </Typography>
                  )}
                </Stack>

                {isReplaceSignatory === signatory.id ? (
                  <Box mt={2}>
                    <AddSignatories
                      signatoriesData={signatoriesData || []}
                      onSubmit={(data: any) => onSubmit(data, signatory, index)}
                      handleUserType={handleUserType}
                      instance={instance}
                      externalUsersData={externalUsersData}
                      isLoading={loadingTempSignatory || false}
                      isReplacing
                      userType={signatory.user_type}
                      handleCancel={() => setIsReplaceSignatory(null)}
                    />
                  </Box>
                ) : (
                  showSignButton(signatory) &&
                  !declinedSignatories?.length && (
                    <>
                      {signButton(signatory)?.disabled ? (
                        <CustomChip
                          label={signButton(signatory)?.text}
                          sx={{ marginTop: '10px' }}
                        />
                      ) : (
                        <Stack direction="row" marginTop={1}>
                          <IconButton
                            style={{
                              transform: 'scaleX(-1)',
                              paddingRight: '15px',
                            }}
                          >
                            <KeyboardReturn />
                          </IconButton>
                          {isSubmitDisabled ? (
                            <Tooltip title="Click here to redirect to signature box">
                              <LoadingButton
                                loading={isLoading}
                                onClick={goToSignField}
                                variant="outlined"
                                className={
                                  !isActive &&
                                  (signatory?.assignee_type !== 'external' ||
                                    signatory?.user_type !== 'external')
                                    ? ''
                                    : 'pulse-animation'
                                }
                                disabled={
                                  !isActive &&
                                  (signatory?.assignee_type !== 'external' ||
                                    signatory?.user_type !== 'external')
                                }
                              >
                                Sign
                              </LoadingButton>
                            </Tooltip>
                          ) : (
                            <Stack direction="row">
                              <LoadingButton
                                loading={isLoading}
                                onClick={() => setAddedSignatureFields(true)}
                              >
                                Submit
                              </LoadingButton>
                              <LoadingButton
                                loading={isLoading}
                                onClick={handleClearSignature}
                              >
                                Clear signature
                              </LoadingButton>
                            </Stack>
                          )}
                          <LoadingButton
                            loading={isLoading}
                            onClick={openDeclineDialog}
                            disabled={
                              !isActive &&
                              (signatory?.assignee_type !== 'external' ||
                                signatory?.user_type !== 'external')
                            }
                          >
                            Decline
                          </LoadingButton>
                        </Stack>
                      )}
                    </>
                  )
                )}
              </Box>
            );
          })}
        {decline && (
          <CustomModal
            title="Decline signature"
            handleClose={closeDeclineDialog}
            open={decline}
          >
            <Typography margin={4} variant="body1">
              Are you sure you want to decline giving your signature?
            </Typography>
            <Box>
              <Button variant="contained" onClick={closeDeclineDialog}>
                No, go back
              </Button>
              <Button variant="outlined" onClick={handleDecline}>
                Yes, decline
              </Button>
            </Box>
          </CustomModal>
        )}

        {addedSignatureFields && (
          <CustomModal
            title="Confirm Signature"
            handleClose={handleCloseConfirmation}
            open={addedSignatureFields}
          >
            <Typography margin={4} variant="body1">
              Please click on "Confirm Signature" button below to record your
              signature or click on "Close" to go back.
            </Typography>
            <Box>
              <LoadingButton
                loading={isLoading || loadingConfirmations}
                variant="contained"
                onClick={submitSignature}
              >
                Confirm Signature
              </LoadingButton>
              <Button variant="outlined" onClick={handleCloseConfirmation}>
                Close
              </Button>
            </Box>
          </CustomModal>
        )}
        {userIsOwner &&
          draftData?.signature_method === 'Stylus (Riverus)' &&
          !declinedSignatories?.length &&
          draftData?.status !== 'Contract Executed Successfully' &&
          !isAllSignatorySigned &&
          !isExternal && (
            <Button
              variant="outlined"
              size="small"
              sx={{
                padding: '8px 22px',
                whiteSpace: 'nowrap',
                ml: 'auto',
              }}
              onClick={handleResendSignatureRequest}
              startIcon={<ForwardToInboxIcon />}
            >
              Resend Signature Request
            </Button>
          )}
      </Stack>

      {agreementStatus && (
        <Typography margin={4} fontSize="18px" fontWeight="600">
          Status: {agreementStatus}
        </Typography>
      )}
      {signatureDetailsData?.length > 0 && agreementStatus === 'SIGNED' && (
        <>
          <Typography margin={4} fontSize="18px" fontWeight="600">
            <Stack margin={4} sx={{ paddingBottom: 2 }} spacing={2}>
              Signed By:
            </Stack>
            <ul>
              {signatureDetailsData?.map((element: any, index: number) => (
                <li key={index}>{element.name}</li>
              ))}
            </ul>
          </Typography>
          <Typography margin={4} fontSize="18px" fontWeight={600}>
            <Stack margin={4} sx={{ paddingBottom: 2 }} spacing={2}>
              Signed At:
            </Stack>
            <Stack margin={4} spacing={2}>
              {signatureDetailsData?.[0]?.signed_at}
            </Stack>
          </Typography>
        </>
      )}
      {replaceAlert && (
        <CustomModal
          title="Replace signatory"
          handleClose={() => setShowReplaceAlert(false)}
          open={replaceAlert}
        >
          <Typography margin={4} variant="body1">
            Replacing the signatory is not enabled on this version, if you wish
            to replace any signatory then request you to re- start the signature
            process by uploading the final version once more.
          </Typography>
          <Box ml={3}>
            <Button
              variant="contained"
              onClick={() => setShowReplaceAlert(false)}
            >
              Ok
            </Button>
          </Box>
        </CustomModal>
      )}
    </>
  );
};

export default SignatoriesList;

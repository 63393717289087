import React, { useCallback, useMemo } from 'react';

import { LoadingButton } from '@mui/lab';
import { Stack, Tooltip } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import { useUserData } from '../../../App/Component/UserDataProvider';
import CustomChip from '../../../Approvals/Component/CreateApprovalForm/CustomChip';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import { fetchGroups } from '../../../Services/Approval';
import { editDraftData, getDraftById } from '../../../Services/Draft';
import { draftStatus } from '../../State/DraftState';
import { droppedMsg, sortListAlphabetical } from '../Helper';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  draftId: GridRowId;
}

const EditDraftGroup: React.FC<Props> = ({ open, onClose, draftId }) => {
  const { data: draftData } = useQuery({
    queryKey: ['get_draft_by_id', draftId],
    queryFn: async () => {
      const response = await getDraftById(draftId as string);
      return response as any;
    },
    enabled: !!draftId,
  });

  const { user_id } = useUserData();

  const isOwner = useMemo(() => {
    const index = draftData?.owners?.findIndex(
      (owner: any) => owner?.id === user_id
    );
    if (index > -1) {
      return true;
    }
    return false;
  }, [draftData, user_id]);

  const methods = useForm();
  const { handleSubmit, control, reset } = methods;

  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  React.useEffect(() => {
    reset({
      groups: draftData?.groups || [],
    });
  }, [draftData, reset]);

  const { mutate: updateDraftData, isPending: loadingUpdate } = useMutation({
    mutationKey: ['edit-groups'],
    mutationFn: editDraftData,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['drafts'],
      });
      enqueueSnackbar('Successfully updated Groups!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to update Groups', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { data: groupListData, isLoading: groupLoading } = useQuery({
    queryKey: ['GroupList'],
    queryFn: fetchGroups,
  });

  const onSubmit = useCallback(
    (data: any) => {
      if (data?.groups) {
        const payload = {
          id: draftId,
          body: {
            id: draftId,
            ...data,
          },
        };
        updateDraftData(payload);
      }
    },
    [draftId, updateDraftData]
  );

  return (
    <CustomModal
      maxWidth="md"
      title="Edit Group"
      open={open}
      handleClose={onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: '500px', padding: '30px 10px' }}>
            <RISelectComponent
              name="groups"
              control={control}
              label="Prescribing Department"
              valueKey="name"
              options={sortListAlphabetical(groupListData)}
              loading={groupLoading}
              isMultiselect={true}
              fixedValues={draftData?.groups}
              renderCustomComponent={(value: any) => (
                <CustomChip key={value?.id} label={value?.name} />
              )}
            />
            <Tooltip
              title={droppedMsg}
              arrow
              disableHoverListener={
                draftData?.status !== draftStatus?.Draft_Dropped
              }
            >
              <Stack>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  type="submit"
                  loading={loadingUpdate}
                  disabled={
                    !isOwner || draftData?.status === draftStatus?.Draft_Dropped
                  }
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Tooltip>
          </Stack>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default EditDraftGroup;

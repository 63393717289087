import React, { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import ControlledTextField from '../RiverusUI/Components/ControlledTextField';
import CustomModal from '../RiverusUI/Components/CustomModal';
import { createUser, editUser } from '../Services/keycloak';
import { QueryKeyGenerator } from '../Utils/QueryKeyGenerator';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  editDetails?: any;
  usersTableKey?: any;
}

const CreateUsersModal: React.FC<Props> = ({
  open,
  onClose,
  editDetails,
  usersTableKey,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm();
  const queryClient = useQueryClient();
  const { handleSubmit, control, setValue } = methods;

  useEffect(() => {
    if (editDetails) {
      setValue('id', editDetails?.keycloak_id);
      setValue('username', editDetails?.username);
      setValue('first_name', editDetails?.first_name);
      setValue('last_name', editDetails?.last_name);
      setValue('email', editDetails?.email);
    }
  }, [editDetails, setValue]);

  const { mutate: createUserMutation, isPending: loadingCreateUser } =
    useMutation({
      mutationFn: createUser,
      onSuccess: () => {
        onClose();
        queryClient.invalidateQueries({
          queryKey: [
            QueryKeyGenerator.getRequestApproval(),
            'all-users',
            usersTableKey,
          ],
        });
        enqueueSnackbar('User created successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
      onError: () => {
        enqueueSnackbar('Failed to create User!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: editUserMutation, isPending: loadingEditUser } = useMutation({
    mutationFn: editUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeyGenerator.getRequestApproval(),
          'all-users',
          usersTableKey,
        ],
      });
      enqueueSnackbar('User edited successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to edit User!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const onSubmit = (data: any) => {
    if (!editDetails) {
      const payload = {
        username: data?.username,
        email: data?.email,
        firstName: data?.first_name,
        lastName: data?.last_name,
      };
      createUserMutation(payload);
    } else {
      const payload = {
        id: editDetails?.keycloak_id,
        body: {
          username: editDetails?.username,
          email: editDetails?.email,
          firstName: data?.first_name,
          lastName: data?.last_name,
          enabled: true,
        },
      };
      editUserMutation(payload);
    }
  };

  return (
    <CustomModal
      title={`${editDetails ? 'Edit' : 'Create'} a User`}
      maxWidth="lg"
      open={open}
      handleClose={onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} width="600px" sx={{ padding: '30px 15px 15px' }}>
            <ControlledTextField
              name="username"
              control={control}
              required
              label="Username"
              fullWidth
              disabled={!!editDetails}
            />
            <ControlledTextField
              name="email"
              control={control}
              label="Email"
              fullWidth
              type="email"
              required
              disabled={!!editDetails}
            />
            <Stack direction="row" spacing={2}>
              <ControlledTextField
                name="first_name"
                control={control}
                required
                label="First Name"
                fullWidth
              />
              <ControlledTextField
                name="last_name"
                control={control}
                required
                label="Last Name"
                fullWidth
              />
            </Stack>
            <Stack direction="row" width="100%" spacing={2}>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={loadingCreateUser || loadingEditUser}
              >
                {editDetails ? 'Save' : 'Create'}
              </LoadingButton>
              <Button
                variant="outlined"
                startIcon={<CloseIcon />}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default CreateUsersModal;

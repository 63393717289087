import React, { Dispatch, SetStateAction, useState } from 'react';

import { Box } from '@mui/material';
import { GridColDef, GridRowId, GridSortModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { isArray } from 'lodash-es';
import { useDebouncedCallback } from 'use-debounce';

import { sortListAlphabetical } from '../../../Draft/Component/Helper';
import { IObject } from '../../../interfaces/IObject';
import DataGridTable from '../../../RiverusUI/DataGrid/DataGridTable';
import { GridCellExpand } from '../../../RiverusUI/DataGrid/GridCellExpand';
import NameAvatar from '../../../RiverusUI/DataGrid/NameAvatar';
import TableAsyncSelectInput from '../../../RiverusUI/DataGrid/TableAsyncSelectInput';
import TableChipList from '../../../RiverusUI/DataGrid/TableChipList';
import TableHeaderWithSearch from '../../../RiverusUI/DataGrid/TableHeaderWIthSearch';
import TableSearchInputComponent from '../../../RiverusUI/DataGrid/TableSearchInputComponent';
import { fetchGroups, getUsers } from '../../../Services/Approval';
import {
  fetchTemplateTags,
  getContractTypes,
} from '../../../Services/Template';
import { selectTypeDocument } from '../StaticData';

interface Props {
  setSelectedRow: Dispatch<SetStateAction<GridRowId[]>>;
  data: any;
  isLoading: boolean;
  pageNumber: number;
  setPageNumberChange: Dispatch<SetStateAction<number>>;
  setFilters: Dispatch<SetStateAction<IObject>>;
  setSorting?: Dispatch<SetStateAction<GridSortModel>>;
  filters?: IObject;
  selectedRow?: GridRowId[];
  checkBoxSelection?: boolean;
  page: string;
  activeTab: string;
}

const TemplateTable = (props: Props) => {
  const {
    setSelectedRow,
    data,
    isLoading,
    pageNumber,
    setPageNumberChange,
    setFilters,
    setSorting,
    filters,
    selectedRow,
    checkBoxSelection = false,
    page,
    activeTab,
  } = props;

  const [searchDraft, setSearchDraft] = useState<boolean>(false);
  const [searchGroups, setSearchGroups] = useState<boolean>(false);
  const [searchTags, setSearchTags] = useState<boolean>(false);
  const [searchAssignee, setSearchAssignee] = useState<boolean>(false);
  const [searchTemplateType, setSearchTemplateType] = useState<boolean>(false);
  const [searchContractType, setSearchContractType] = useState<boolean>(false);

  const { data: tags } = useQuery({
    queryKey: ['template-tags'],
    queryFn: fetchTemplateTags,
    select: (response: any) => {
      return response.results.map((data: any) => {
        const newData = {
          id: data.id,
          name: data.tag_name,
        };
        return newData;
      });
    },
  });

  const { data: groups } = useQuery({
    queryKey: ['groups'],
    queryFn: fetchGroups,
  });

  const { data: contracts } = useQuery({
    queryKey: ['template-contracts'],
    queryFn: getContractTypes,
    select: (response: any) => response.results,
  });

  const { data: authorData } = useQuery({
    queryKey: ['author'],
    queryFn: async () => {
      const response = await getUsers();
      const users = response?.users || [];
      for (let i = 0; i < users.length; i++) {
        const name = users[i].first_name + ' ' + users[i].last_name;
        users[i].name = name;
      }
      return users;
    },
  });

  const handleFilterChange = useDebouncedCallback(
    (label: string, value: string | string[]) => {
      if (value?.length) {
        setPageNumberChange(0);
      }
      setFilters((prev: IObject) => ({ ...prev, [label]: value }));
    },
    1000
  );

  const handleGroupClick = React.useCallback(
    (groups: any) => {
      handleFilterChange('group', groups);
      setSearchGroups(true);
    },
    [handleFilterChange]
  );

  const handleTagClick = React.useCallback(
    (tags: any) => {
      handleFilterChange('tags', tags);
      setSearchTags(true);
    },
    [handleFilterChange]
  );

  const columns = React.useMemo<GridColDef<any>[]>(
    () => [
      {
        field: 'file_name',
        headerName: 'Title',
        minWidth: 250,
        flex: 1,
        sortable: !searchDraft,
        renderHeader: () => {
          return searchDraft ? (
            <TableSearchInputComponent
              key="name"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, name: null }));
                setSearchDraft(false);
              }}
              placeholder="Search Title"
              handleChange={(e: React.BaseSyntheticEvent<HTMLInputElement>) =>
                handleFilterChange('name', e.target.value)
              }
            />
          ) : (
            <TableHeaderWithSearch
              title="Template Name"
              setIsSearch={setSearchDraft}
            />
          );
        },
        renderCell: (Params: any) => {
          return (
            <GridCellExpand
              value={Params?.row?.name || Params?.row?.file_name}
              width={Params.colDef.computedWidth}
              onClick={() => {
                window.open(`/template/${Params.row.id}/${page}`);
              }}
              cellStyle={{
                cursor: 'pointer',
              }}
            />
          );
        },
      },
      activeTab === 'template'
        ? {
            field: 'contract_type',
            headerName: 'Contract Type',
            maxWidth: 300,
            width: 250,
            sortable: false,
            renderHeader: () => {
              return searchContractType ? (
                <TableSearchInputComponent
                  key="contract_type"
                  setIsSearch={() => {
                    setFilters((prev) => ({ ...prev, contract_type: null }));
                    setSearchContractType(false);
                  }}
                  placeholder="Search Contract type"
                  renderCustomInput={() => (
                    <TableAsyncSelectInput
                      key={
                        isArray(filters?.contract_type)
                          ? (filters?.contract_type?.[0] as string)
                          : 'contract_type'
                      }
                      options={sortListAlphabetical(contracts)}
                      label="Select Contract type"
                      valueKey="id"
                      value={
                        filters?.contract_type ? filters.contract_type : []
                      }
                      onChange={(value: string[]) =>
                        handleFilterChange('contract_type', value)
                      }
                    />
                  )}
                />
              ) : (
                <TableHeaderWithSearch
                  title="Contract Type"
                  setIsSearch={setSearchContractType}
                />
              );
            },
            renderCell: (Params) => (
              <Box>{Params?.row?.contract_type?.name || 0}</Box>
            ),
          }
        : {
            field: 'template_type',
            headerName: 'Template Type',
            maxWidth: 300,
            width: 250,
            sortable: false,
            renderHeader: () => {
              return searchTemplateType ? (
                <TableSearchInputComponent
                  key="template_type"
                  setIsSearch={() => {
                    setFilters((prev) => ({ ...prev, template_type: null }));
                    setSearchTemplateType(false);
                  }}
                  placeholder="Search template type"
                  renderCustomInput={() => (
                    <TableAsyncSelectInput
                      key={
                        isArray(filters?.template_type)
                          ? (filters?.template_type?.[0] as string)
                          : 'template_type'
                      }
                      options={selectTypeDocument}
                      label="Select template type"
                      valueKey="name"
                      value={
                        filters?.template_type ? filters.template_type : []
                      }
                      onChange={(value: string[]) =>
                        handleFilterChange('template_type', value)
                      }
                    />
                  )}
                />
              ) : (
                <TableHeaderWithSearch
                  title="Template Type"
                  setIsSearch={setSearchTemplateType}
                />
              );
            },
          },
      {
        field: 'groups',
        headerName: 'Groups',
        maxWidth: 300,
        width: 250,
        sortable: false,
        minWidth: 150,
        renderCell: (params) => {
          const selectedFilters: any = filters?.group || [];
          let reOrderGroups = params?.row?.group || [];
          if (selectedFilters?.length) {
            const filterSelectedGroups = reOrderGroups?.filter((item: any) =>
              selectedFilters?.includes(item?.name)
            );
            const filterNotSelectedGroups = reOrderGroups?.filter(
              (item: any) => !selectedFilters?.includes(item?.name)
            );
            reOrderGroups = [
              ...filterSelectedGroups,
              ...filterNotSelectedGroups,
            ];
          }
          return (
            <TableChipList
              id={`group-${params?.row?.id}`}
              chipColor="#DCBDE7"
              list={reOrderGroups}
              onClick={handleGroupClick}
            />
          );
        },
        renderHeader: () => {
          return searchGroups ? (
            <TableSearchInputComponent
              key="group"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, group: null }));
                setSearchGroups(false);
              }}
              placeholder="Search Groups"
              renderCustomInput={() => (
                <TableAsyncSelectInput
                  key={
                    isArray(filters?.group)
                      ? (filters?.group?.[0] as string)
                      : 'group'
                  }
                  options={sortListAlphabetical(groups)}
                  label="Select Groups"
                  valueKey="name"
                  value={filters?.group ? filters.group : []}
                  onChange={(value: string[]) =>
                    handleFilterChange('group', value)
                  }
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              title="Groups"
              setIsSearch={setSearchGroups}
            />
          );
        },
      },
      {
        field: 'tags',
        headerName: 'Tags',
        minWidth: 230,
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          const selectedFilters: any = filters?.tags || [];
          let reOrderTags = params?.row?.tags || [];
          if (selectedFilters?.length) {
            const filterSelectedTags = reOrderTags?.filter((item: any) =>
              selectedFilters?.includes(item?.tag_name)
            );
            const filterNotSelectedTags = reOrderTags?.filter(
              (item: any) => !selectedFilters?.includes(item?.tag_name)
            );
            reOrderTags = [...filterSelectedTags, ...filterNotSelectedTags];
          }
          return (
            <TableChipList
              id={`tags-${params?.row?.id}`}
              labelKey="tag_name"
              valueKey="tag_name"
              chipColor="#DCBDE7"
              list={reOrderTags}
              onClick={handleTagClick}
            />
          );
        },
        renderHeader: () => {
          return searchTags ? (
            <TableSearchInputComponent
              key="tags"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, tags: null }));
                setSearchTags(false);
              }}
              placeholder="Search Tags"
              renderCustomInput={() => (
                <TableAsyncSelectInput
                  key={
                    isArray(filters?.tags)
                      ? (filters?.tags?.[0] as string)
                      : 'tags'
                  }
                  options={sortListAlphabetical(tags)}
                  valueKey="name"
                  label="Select Tags"
                  value={filters?.tags ? filters.tags : []}
                  onChange={(value: string[]) =>
                    handleFilterChange('tags', value)
                  }
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch title="Tags" setIsSearch={setSearchTags} />
          );
        },
      },
      {
        field: 'created_by',
        headerName: 'Uploaded By',
        maxWidth: 260,
        width: 220,
        sortable: false,
        minWidth: 150,
        renderHeader: () => {
          return searchAssignee ? (
            <TableSearchInputComponent
              key="created_by"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, created_by: null }));
                setSearchAssignee(false);
              }}
              placeholder="Search uploader"
              renderCustomInput={() => (
                <TableAsyncSelectInput
                  key={
                    isArray(filters?.created_by)
                      ? (filters?.created_by?.[0] as string)
                      : 'created_by'
                  }
                  options={sortListAlphabetical(authorData)}
                  label="Select user"
                  value={filters?.created_by ? filters.created_by : []}
                  onChange={(value: string[]) =>
                    handleFilterChange('created_by', value)
                  }
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              title="Uploaded By"
              setIsSearch={setSearchAssignee}
            />
          );
        },
        renderCell: (Params) => {
          return (
            <NameAvatar
              firstName={Params?.row?.created_by?.first_name}
              lastName={Params?.row?.created_by?.last_name}
            />
          );
        },
      },
      {
        field: 'draft_count',
        headerName: 'Contracts',
        minWidth: 120,
        flex: 1,
        sortable: true,
        renderCell: (Params) => <Box>{Params?.row?.used_in_drafts || 0}</Box>,
      },
    ],
    [
      searchDraft,
      setFilters,
      handleFilterChange,
      page,
      handleGroupClick,
      searchGroups,
      filters,
      groups,
      handleTagClick,
      searchTags,
      tags,
      searchAssignee,
      authorData,
      activeTab,
      searchTemplateType,
      searchContractType,
    ]
  );

  return (
    <DataGridTable
      rows={data?.results || []}
      columns={columns}
      rowCount={data?.count}
      isLoading={isLoading}
      setSelectedRow={setSelectedRow}
      pageNumber={pageNumber}
      setPageNumberChange={setPageNumberChange}
      setSorting={setSorting}
      checkboxSelection={checkBoxSelection}
      selectedRow={selectedRow}
      columnVisibilityModel={{
        groups: activeTab === 'template' ? true : false,
      }}
    />
  );
};

export default TemplateTable;

import React, { useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import ControlledTextField from '../RiverusUI/Components/ControlledTextField';
import CustomModal from '../RiverusUI/Components/CustomModal';
import ReusableConfirmationModal from '../RiverusUI/Components/ReusableConfirmationModal';
import {
  deleteUsersCredentials,
  getUsersCredentials,
  setUsersCredentials,
} from '../Services/keycloak';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  editDetails?: any;
}

const PasswordModal: React.FC<Props> = ({ open, onClose, editDetails }) => {
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm();
  const {
    handleSubmit,
    control,
    watch,
    setError,
    formState: { errors },
  } = methods;

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [isOn, setIsOn] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedCredential, setSelectedCredential] = useState<{
    id: string;
    credId: string;
  } | null>(null);
  const [isReset, setIsReset] = useState(false);

  const password = watch('password');
  const passwordConfirmation = watch('passwordConfirmation');

  const { data: credentialsData } = useQuery({
    queryKey: ['get_credential_data', editDetails?.keycloak_id],
    queryFn: () => getUsersCredentials(editDetails?.keycloak_id as string),
    select: (response: any) => {
      return response;
    },
    enabled: !!editDetails?.keycloak_id,
  });

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleTogglePasswordConfirmationVisibility = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOn(event.target.checked);
  };

  const { mutate: setUsersCredentialsMutation, isPending: buttonLoading } =
    useMutation({
      mutationFn: setUsersCredentials,
      onSuccess: () => {
        enqueueSnackbar('Credentials added successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        onClose();
      },
      onError: () => {
        enqueueSnackbar('Failed to add Credentials!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: deleteUsersCredentialsMutation, isPending: loadingButton } =
    useMutation({
      mutationFn: deleteUsersCredentials,
      onSuccess: () => {
        enqueueSnackbar('Credentials deleted successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        onClose();
        setOpenDeleteModal(false);
      },
      onError: () => {
        enqueueSnackbar('Failed to delete Credentials!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const handleOpenDeleteModal = (id: string, credId: string) => {
    setOpenDeleteModal(true);
    setSelectedCredential({ id, credId });
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedCredential(null);
  };

  const handleDeleteCredentials = () => {
    if (selectedCredential) {
      deleteUsersCredentialsMutation(selectedCredential);
    }
  };

  const handleResetClick = () => {
    setIsReset((prevState) => !prevState);
  };

  const columns = [
    { field: 'type', headerName: 'Type', flex: 3, width: 100 },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params: any) => {
        const isPasswordType = params?.row?.type === 'password';
        return (
          <Stack>
            {isPasswordType ? (
              <Button
                onClick={handleResetClick}
                variant="contained"
                sx={{ height: '2rem', margin: 0 }}
              >
                Reset
              </Button>
            ) : (
              <Button
                onClick={() =>
                  handleOpenDeleteModal(editDetails?.keycloak_id, params.row.id)
                }
                variant="outlined"
                sx={{ height: '2rem', margin: 0 }}
              >
                Delete
              </Button>
            )}
          </Stack>
        );
      },
      width: 80,
    },
  ];

  const rows = credentialsData?.map((credential: any) => ({
    id: credential?.id,
    type: credential?.type,
  }));

  const onSubmit = (data: any) => {
    if (password !== passwordConfirmation) {
      setError('passwordConfirmation', {
        type: 'manual',
        message: 'Password does not match',
      });
      return;
    }

    const payload = {
      id: editDetails?.keycloak_id,
      body: {
        temporary: isOn ? true : false,
        type: 'password',
        value: data?.password,
      },
    };
    setUsersCredentialsMutation(payload);
  };

  return (
    <CustomModal
      title={`Manage Credentials for ${editDetails?.first_name} ${editDetails?.last_name}`}
      maxWidth="lg"
      open={open}
      handleClose={onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} width="550px" sx={{ padding: '30px 15px 15px' }}>
            {(isReset || credentialsData?.length > 0) && (
              <DataGrid
                rows={rows}
                columns={columns.map((col) => ({ ...col, sortable: false }))}
                disableColumnMenu
                components={{
                  Footer: () => null,
                }}
                sx={{
                  '.MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '&.MuiDataGrid-root': {
                    border: 'none',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: 'riPrimary.10',
                    fontSize: 16,
                    borderRadius: '50px',
                    borderBottom: 'none',
                  },
                }}
              />
            )}
            {(credentialsData?.length === 0 || isReset) && (
              <>
                <Stack alignItems="center">
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    fontWeight={600}
                  >
                    {!isReset
                      ? 'No Credentials Available!'
                      : 'Reset Credentials!'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {!isReset
                      ? 'This user does not have any credentials. You can set a password for this user.'
                      : 'This user has credentials. You can reset the password for this user.'}
                  </Typography>
                </Stack>
                <ControlledTextField
                  name="password"
                  control={control}
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  required
                  error={!!errors.password}
                  helperText={errors.password?.message as string}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ControlledTextField
                  name="passwordConfirmation"
                  control={control}
                  label="Password Confirmation"
                  type={showPasswordConfirmation ? 'text' : 'password'}
                  required
                  error={!!errors.passwordConfirmation}
                  helperText={errors.passwordConfirmation?.message as string}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordConfirmationVisibility}
                          edge="end"
                        >
                          {showPasswordConfirmation ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Stack direction="row" spacing={5} alignItems="center">
                  <Typography>Temporary</Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isOn}
                        onChange={handleChange}
                        name="toggle"
                        color="primary"
                      />
                    }
                    label={`${isOn ? 'On' : 'Off'}`}
                  />
                </Stack>
                <Stack
                  direction="row"
                  width="100%"
                  spacing={2}
                  justifyContent="end"
                >
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={buttonLoading}
                  >
                    {!isReset ? 'Save' : 'Reset'}
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    startIcon={<CloseIcon />}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </Stack>
              </>
            )}
          </Stack>
        </form>
      </FormProvider>
      {openDeleteModal && (
        <ReusableConfirmationModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          title="Delete Credentials"
          cancelBtnText="Cancel"
          confirmBtnText="Delete"
          onConfirm={handleDeleteCredentials}
          buttonLoading={loadingButton}
        >
          <Stack spacing={1}>
            <Typography>Are you sure?</Typography>
            <Typography>
              The selected credentials will be removed permanently.
            </Typography>
            <Typography>Note: This action is not reversible.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </CustomModal>
  );
};

export default PasswordModal;
